exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brothers-tsx": () => import("./../../../src/pages/brothers.tsx" /* webpackChunkName: "component---src-pages-brothers-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-templates-blocks-tsx": () => import("./../../../src/templates/blocks.tsx" /* webpackChunkName: "component---src-templates-blocks-tsx" */),
  "component---src-templates-list-tsx": () => import("./../../../src/templates/list.tsx" /* webpackChunkName: "component---src-templates-list-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-single-tsx": () => import("./../../../src/templates/single.tsx" /* webpackChunkName: "component---src-templates-single-tsx" */)
}

